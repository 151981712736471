import React from 'react';
import styled from 'styled-components';

import { useViewport } from '../../hooks/useViewport';

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 49.2vw 50.8vw;
  grid-template-rows: 100%;
  background-image: url(${props => props.background});
  background-size: cover;
  margin-top: 140px;
`;

const Left = styled.div`
  margin-left: 107px;
  grid-column: 1;
  grid-row: 1;
  margin-top: 152px;

  .title-container {
    padding-left: 13px;
  }

  .title {
    font-family: 'Chapaza';
    font-size: 60px;
    color: #FFFFFF;
    
    @media (max-width: 1024px) {
      font-size: 48px;
    }
  }

  .line {
    margin-left: 13px;
    width: 7vw;
    height: 2px;
    background-color: #212028;
    margin-top: 51px;
  }

  .text {
    margin-top: 47px;
    font-family: 'Lato';
    font-size: 22px;
    color: #FFFFFF;
  }
`;

const MobileContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-image: url(${props => props.mobileBackground});
  background-size: cover;

  .layer {
    background-color: #b94f3d;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.85;
    position: absolute;
  }

   .title {
    font-family: Chapaza;
    font-size: 10.5vw;
    color: #F3F3F3;
    margin: 10%;
    z-index: 99;
  }

  .text {
    font-family: 'Lato';
    font-size: 3.7vw;
    color: #FFFFFF;
    max-width: 80%;
    z-index: 99;
  }
`;

const GradiantImage = ({ title, text, background, mobileBackground }) => {
  const { width } = useViewport();
  const breakpoint = 768;

  return (
    width <= breakpoint ? (
      <MobileContainer mobileBackground={mobileBackground}>
        <div className="title">{title}</div >
        <div className="text">
          {text}
        </div>
        <div className="layer" />
      </MobileContainer>
    ) : (
        <Container background={background}>
          <Left>
            <div className="title">{title}</div >
            <div className="line" />
            <div className="text">
              {text}
            </div>
          </Left >
        </Container >
      )

  )
}

export default GradiantImage;