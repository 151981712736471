import React from "react"
import styled from "styled-components"
import { useViewport } from "../../../hooks/useViewport"
import background from "../../../images/bio-about-bg.png"
import mobileBackground from "../../../images/mobile/About_Bill_Armstrong.png"
import MobileContainer from "../GradiantImage"

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 54.2vw 45.8vw;
  grid-template-rows: 100%;
  background-image: url(${background});
  background-size: cover;
  margin-top: 140px;
`

const Left = styled.div`
  grid-column: 1;
  grid-row: 1;
  margin-top: 121px;
  margin-left: 99px;

  .about {
    font-family: "Lato";
    font-weight: lighter;
    font-size: 2.25vw;
    color: #212028;
  }

  .senator-container {
    padding-left: 13px;
  }

  .senator {
    font-family: "Chapaza";
    font-size: 60px;
    color: #ffffff;

    @media (max-width: 1360px) {
      font-size: 48px;
    }
  }

  .line {
    margin-left: 13px;
    width: 7vw;
    height: 2px;
    background-color: #212028;
    margin-top: 70px;
  }

  .text {
    font-family: "Lato";
    font-size: 22px;
    color: #ffffff;
    margin-top: 46px;

    @media (max-width: 1360px) {
      font-size: 20px;
      margin-top: 36px;
    }
  }
`

const Section1 = () => {
  const { width } = useViewport()
  const breakpoint = 768

  return width <= breakpoint ? (
    <MobileContainer
      title={"About Senator Bill Armstrong"}
      text={
        "William L. Armstrong was born in Fremont, Nebraska March 16, 1937, the son of William L. Armstrong, Sr. and Dorothy Steen Armstrong. His maternal grandfather was a Presbyterian minister who pastored several churches in Illinois and Nebraska, and his great-great uncle was Alexander Majors, co-founder of the Pony Express. Armstrong was in every sense a child of the West, who grew up hearing family stories of pioneer days. His uncle, Paul Armstrong, served in World War II, while young Bill’s father took over the family hardware and furniture business. Bill became a lifelong supporter of the American military, and served in the National Guard while working at a Minnesota radio station."
      }
      mobileBackground={mobileBackground}
    />
  ) : (
    <Container>
      <Left>
        <div className="senator-container">
          <div className="about">About</div>
          <div className="senator">Senator</div>
          <div className="senator">Bill Armstrong</div>
        </div>
        <div className="line" />
        <div className="text">
          William L. Armstrong was born in Fremont, Nebraska March 16, 1937, the
          son of William L. Armstrong, Sr. and Dorothy Steen Armstrong. His
          maternal grandfather was a Presbyterian minister who pastored several
          churches in Illinois and Nebraska, and his great-great uncle was
          Alexander Majors, co-founder of the Pony Express. Armstrong was in
          every sense a child of the West, who grew up hearing family stories of
          pioneer days. His uncle, Paul Armstrong, served in World War II, while
          young Bill’s father took over the family hardware and furniture
          business. Bill became a lifelong supporter of the American military,
          and served in the National Guard while working at a Minnesota radio
          station.
        </div>
      </Left>
    </Container>
  )
}

export default Section1
