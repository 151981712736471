import React from "react"
import styled from "styled-components"
import { useViewport } from "../../../hooks/useViewport"
import image from "../../../images/Group 3 Copy@2x.png"
import MobileContainer from "../ImageLeftWhiteBGContainer"

const Container = styled.div`
  width: 100%;
  height: 83vh;
  display: grid;
  grid-template-columns: 49.2vw 50.8vw;
  grid-template-rows: 100%;

  .text {
    font-family: "Lato";
    font-size: 22px;
    color: #212028;
    height: 21.9vh;
    width: 40vw;
    grid-column: 1;
    grid-row: 1;
    align-self: center;
    margin-left: 107px;

    @media (max-width: 1360px) {
      font-size: 20px;
    }
  }

  img {
    grid-column: 2;
    grid-row: 1;
    max-width: 35vw;
    max-height: 85vh;
    align-self: start;
    margin-left: 121px;
  }

  .caption {
    grid-column: 2;
    grid-row: 1;
    align-self: end;
    font-family: "Lato";
    font-size: 20px;
    width: max-content;
    margin-left: 121px;
  }
`

const SectionThree = () => {
  const { width } = useViewport()
  const breakpoint = 768

  return width <= breakpoint ? (
    <MobileContainer
      captionBlack={"Bill Armstrong served for 18 years in the"}
      captionOrange={"House and Senate."}
      text={
        "1984 was among the largest landslides in State history, and his Senate colleagues elected him Chairman of the Republican Policy Committee, the third highest ranking leadership post. He was touted by conservatives as their candidate for President in 1988, but decided instead to come home to Colorado to “praise the Lord, have some fun, and make some money.” He was enormously successful in business for the rest of his life, including a private equity  partnership with his son, Wil."
      }
      image={image}
    />
  ) : (
    <Container>
      <div className="text">
        1984 was among the largest landslides in State history, and his Senate
        colleagues elected him Chairman of the Republican Policy Committee, the
        third highest ranking leadership post. He was touted by conservatives as
        their candidate for President in 1988, but decided instead to come home
        to Colorado to “praise the Lord, have some fun, and make some money.” He
        was enormously successful in business for the rest of his life,
        including a private equity partnership with his son, Wil.
      </div>
      <img src={image} />
      <div className="caption">
        Bill Armstrong served for 18 years in the <br />
        <span style={{ color: "#C84836" }}>House and Senate.</span>
      </div>
    </Container>
  )
}

export default SectionThree
