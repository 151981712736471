import React from 'react';
import Layout from '../../components/layout';
import { Helmet } from 'react-helmet';

import SectionOne from '../../components/bio/about/Section1';

import SectionTwo from '../../components/bio/ImageLeftWhiteBGContainer';
import SectionTwoImage from '../../images/about_sec2.png';

import SectionThree from '../../components/bio/about/Section3';

import AboutSectionFour from '../../components/bio/ImageRightOrangeBGConatiner';
import SectionFourImage from '../../images/about_sec4.png';

import AboutSectionFive from '../../components/bio/BottomSectionContainer';
import SectionFiveImage from '../../images/about_sec5.png';

const About = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Armstrong's life and legacy: About</title>
        <script>
        {`
          (function (s, e, n, d, er) {
            s['Sender'] = er;
            s[er] = s[er] || function () {
              (s[er].q = s[er].q || []).push(arguments)
            }, s[er].l = 1 * new Date();
            var a = e.createElement(n),
                m = e.getElementsByTagName(n)[0];
            a.async = 1;
            a.src = d;
            m.parentNode.insertBefore(a, m)
          })(window, document, 'script', 'https://cdn.sender.net/accounts_resources/universal.js', 'sender');
          sender('a78ebf1b330821')
        `}
      </script>
      </Helmet>
      <Layout stacked={true}>
        <SectionOne />
        <SectionTwo
          image={SectionTwoImage}
          captionBlack={'Running for reelection, '}
          captionOrange={'1984'}
          text={'He never forgot meeting one of his early conservative heroes, Ohio Senator Robert Taft, whose 1948 presidential campaign featured a stop in Fremont, Nebraska, and helped trigger an early interest in politics. He was elected to the Colorado State House of Representatives in 1962 at the age of 25, and to the State Senate two years later, becoming the youngest State Senate Majority Leader in Colorado history. He was elected to Congress in 1972, representing Colorado’s new 5th District, and defeated an incumbent Senator in 1978. His re-election in'}
        />
        <SectionThree />
        <AboutSectionFour
          title={'He was a frequent and popular speaker at Christian gatherings across the country'}
          text={`He was a frequent and popular speaker at Christian gatherings across the country, and in 2006 became president of Colorado Christian University (CCU). Over the next decade, the institution's enrollment doubled, significantly increased its academic performance, and began development of a beautiful new campus at its Lakewood site. Armstrong passed on July 15, 2016 in Denver at the age of 79. The Armstrong Legacy initiative now underway at CCU is the institution’s largest-ever fundraising campaign, which will continue building the campus, including the Armstrong Center, as well as endow new need-based scholarships.`}
          image={SectionFourImage}
          captionWhite={'Campaigning for the U.S. Senate, '}
          captionBlack={'1978'}
        />
        <AboutSectionFive
          image={SectionFiveImage}
          blackCaption={'Armstrong gives listeners a tour at '}
          orangeCaption={'KOSI'}
          blackLink={'Click to next page: '}
          orangeLink={'Broadcaster'}
          route={'/bio/broadcaster'}
        />
      </Layout>
    </>
  )
}

export default About;