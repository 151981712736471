import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useViewport } from '../../hooks/useViewport';

const Container = styled.div`
  height: 725px;
  width: 100%;

  display: grid;
  grid-template-columns: 46.6vw 54.4vw;
  grid-template-rows: 100%;

  img {
    grid-column: 1;
    grid-row: 1;

    width: 95%;
    height: 95%;
    object-fit: contain;
    margin-left: 113px;
  }

  .caption {
    font-family: 'Lato';
    font-size: 20px;
    color: black;
    margin-left: 106px;
    grid-column: 1;
    grid-row: 1;
    align-self: end;
    margin-bottom: 55px;
    letter-spacing: 1px;
  }

  .next-page {
    grid-column: 2;
    grid-row: 1;
    font-family: 'Lato';
    align-self: center;
    font-size: 20px;
    width: 326px;
    margin-left: 100px;
    text-decoration: none;
    color: black;
  }
`;

const MobileContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

   img {

    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }

  .caption {
    font-family: 'Lato';
    font-size: 2.3vw;
    color: black;
    letter-spacing: 1px;
    margin: 10%;
  }

  .next-page {
    grid-column: 2;
    grid-row: 1;
    font-family: 'Lato';
    align-self: center;
    font-size: 3vw;
    text-decoration: none;
    color: black;
  }
`;


const BottomSectionContainer = ({ image, blackCaption, orangeCaption, blackLink, orangeLink, route }) => {
  const { width } = useViewport();
  const breakpoint = 768;

  return (
    width <= breakpoint ? (
      <MobileContainer>
        <img src={image} />
        <div className="caption">{blackCaption}<span style={{ color: '#C84836' }}>{orangeCaption}</span></div>
        <Link className="next-page" to={route}>
          <div >{blackLink} <span style={{ color: '#C84836' }}>{orangeLink}</span></div>
        </Link>
      </MobileContainer>
    ) : (
        <Container>
          <img src={image} />
          <div className="caption">{blackCaption}<span style={{ color: '#C84836' }}>{orangeCaption}</span></div>
          <Link className="next-page" to={route}>
            <div >{blackLink} <span style={{ color: '#C84836' }}>{orangeLink}</span></div>
          </Link>
        </Container >
      )

  );
}

export default BottomSectionContainer;