import React from "react"
import styled from "styled-components"
import { useViewport } from "../../hooks/useViewport"

const Container = styled.div`
  display: grid;
  width: 100%;
  height: 932px;
  grid-template-columns: 46.7vw 53.3vw;
  grid-template-rows: 100%;
  margin-top: 60px;

  .right-side {
    grid-column: 2;
    grid-row: 1;
    background-color: #ba503e;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      max-width: 500px;
      max-height: 748px;
    }

    .caption {
      font-family: "Lato";
      font-size: 20px;
      color: white;
      max-width: 455px;
    }
  }

  .left-side {
    grid-column: 1;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    margin-right: 112px;
    margin-left: 107px;
    justify-content: space-around;

    .title {
      font-family: "Chapaza";
      font-size: 45px;
      color: #212028;
      text-align: center;

      @media (max-width: 1360px) {
        font-size: 36px;
      }
    }

    .line {
      width: 5.5vw;
      background-color: #c84836;
      height: 2px;
      margin: 62px auto 0;
    }

    .text {
      font-family: "Lato";
      font-size: 18px;
    }
  }
`

const MobileContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .top {
    background-color: #ba503e;
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-width: 70%;
      max-height: 70%;
      margin: 5%;
    }

    .caption {
      font-family: "Lato";
      font-size: 20px;
      color: white;
      margin: 5%;
    }
  }

  .bottom {
    height: 50vh;
    width: 100%;

    .title {
      font-family: "Chapaza";
      font-size: 4.5vw;
      color: #212028;
      text-align: center;
      margin: 5%;
    }

    .line {
      width: 5.5vw;
      background-color: #c84836;
      height: 2px;
      margin: 62px auto 0;
    }

    .text {
      font-family: "Lato";
      font-size: 3vw;
      margin: 5%;
    }
  }
`

const ImageRightOrangeBGContainer = ({
  title,
  text,
  image,
  captionWhite,
  captionBlack,
}) => {
  const { width } = useViewport()
  const breakpoint = 768

  return width <= breakpoint ? (
    <MobileContainer>
      <div className="top">
        <img src={image} alt="statesman" />
        <div className="caption">
          {captionWhite}
          <span style={{ color: "#212028" }}>{captionBlack}</span>
        </div>
      </div>
      <div className="bottom">
        <div>
          <div className="title">{title}</div>
          <div className="line" />
        </div>
        <div className="text">{text}</div>
      </div>
    </MobileContainer>
  ) : (
    <Container>
      <div className="left-side">
        <div>
          <div className="title">{title}</div>
          <div className="line" />
        </div>
        <div className="text">{text}</div>
      </div>

      <div className="right-side">
        <img src={image} alt="statesman" />
        <div className="caption">
          {captionWhite}
          <span style={{ color: "#212028" }}>{captionBlack}</span>
        </div>
      </div>
    </Container>
  )
}

export default ImageRightOrangeBGContainer
