import React from 'react';
import styled from 'styled-components';

import { useViewport } from '../../hooks/useViewport';

const Container = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 60vw 40vw;
  grid-template-rows: 100%;

  img {
    grid-column: 1;
    grid-row: 1;
    max-width: 80%;
    max-height: 80%;
    align-self: center;
    margin-left: 106px;
  }

  .text {
    font-family: 'Lato';
    font-size: 20px;
    width: 298px;
    height: 484px;
    place-self: center;

    @media (max-width: 1360px) {
      font-size: 18px;
    }
  }

  .caption {
    grid-column: 1;
    grid-row: 1;
    align-self: end;
    font-family: 'Lato';
    font-size: 20px;
    color: black;
    line-height: 24px;
    margin-left: 106px;
    margin-bottom: 113px;
    letter-spacing: 1px;
  }
`;

const MobileContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    max-width: 70%;
    max-height: 70%;
  }

  .text {
    font-family: 'Lato';
    font-size: 3.8vw;
    place-self: center;
    margin: 15%;
  }

  .caption {
    font-family: 'Lato';
    font-size: 2vw;
    color: black;
    line-height: 24px;
  }
`;

const ImageLeftWhiteBGContainer = ({ captionBlack, captionOrange, text, image }) => {
  const { width } = useViewport();
  const breakpoint = 768;

  return (
    width <= breakpoint ? (
      <MobileContainer>
        <img src={image} />
        <div className="caption">{captionBlack}<span style={{ color: '#C84836' }}>{captionOrange}</span></div>
        <div className="text">
          {text}
        </div>
      </MobileContainer>
    ) : (
        <Container>
          <img src={image} />
          <div className="caption">{captionBlack}<span style={{ color: '#C84836' }}>{captionOrange}</span></div>
          <div className="text">
            {text}
          </div>
        </Container >
      )
  )
}

export default ImageLeftWhiteBGContainer;